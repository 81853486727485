import React from "react"
/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const HeaderBgImg = ({ img, img_alt }) => {
  //static image query
  const data = useStaticQuery(graphql`
    {
      defaultImage: file(
        relativePath: { eq: "default-page-script-reading.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const mainImg = img
    ? img.localFile.childImageSharp.fluid
    : data.defaultImage.childImageSharp.fluid
  const mainImgAlt = img_alt ? img_alt : "Default Page Artwork"
  return (
    <Box
      sx={{
        background: "black",
        transform: "rotate(-2deg) skew(-2deg) !important",
        //py: "120px",
        mt: "-230px",
        mb: 4,
      }}
    >
      <Img
        fluid={mainImg}
        alt={mainImgAlt}
        sx={{ height: "400px", opacity: 0.75 }}
      />
    </Box>
  )
}
export default HeaderBgImg
