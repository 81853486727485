import React from "react"
/** @jsx jsx */
import { Box, Grid, jsx, Flex } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import HeaderBlockColor from "../components/headerBlockColor"
import HeaderBgImg from "../components/headerBgImg"
import SanitizedHTMLBodyCopy from "../components/sanitizedHTMLBodyCopy"
import { GoogleMap, LoadScript } from "@react-google-maps/api"

const ContactUsPage = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      strapiContactPage {
        title
        address
        content
        featured_image {
          img_alt
          img_src {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const pageData = data.strapiContactPage
  //maps stuff
  const containerStyle = {
    width: "400px",
    height: "400px",
  }

  const center = {
    lat: 51.50862,
    lng: -0.13158,
  }
  return (
    <Layout>
      <SEO title="Contact Us" pathname="/contact-us" />
      {pageData.featured_image.img_src.localFile ? (
        <HeaderBgImg
          img={pageData.featured_image.img_src}
          img_alt={pageData.featured_image.img_alt}
        />
      ) : (
        <HeaderBlockColor />
      )}
      <Box
        sx={{
          mx: 3,
          background:
            "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
          transform: "rotate(-3deg) skew(-3deg) !important",
          py: 3,
          px: 3,
          position: "relative",
          mt: "-100px",
          mb: 3,
          display: "inline-block",
          "& h1": {
            color: "white",
            transform: "rotate(3deg) skew(3deg) !important",
          },
        }}
      >
        <h1>{pageData.title}</h1>
      </Box>

      <Flex sx={{ mx: 4, display: ["block", "flex", null] }}>
        <Box
          sx={{
            width: ["100%", "60%", null],
            fontSize: 4,
            color: "muted",
            pr: 4,
            "h1,h2,h3,h4,h5,h6": {
              fontSize: 5,
            },
            "& .bodyCopy a": {
              color: "accent",
            },
            "& .bodyCopy img": {
              width: "100%",
            },
            "& .lead": {
              fontSize: 5,
              color: "text",
            },
            "& .small": {
              fontSize: 3,
            },
          }}
        >
          <SanitizedHTMLBodyCopy content={pageData.content} />
        </Box>
        <Box>
          <h2>Location</h2>
          <SanitizedHTMLBodyCopy content={pageData.address} />
          <LoadScript googleMapsApiKey="AIzaSyAWq9x-IbsZwrRqd6UTtSWurWFEftMFH-8">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={14}
              aria-label="Map"
            >
              {/* Child components, such as markers, info windows, etc. */}

              <></>
            </GoogleMap>
          </LoadScript>
        </Box>
      </Flex>
    </Layout>
  )
}

export default ContactUsPage
